import { library, config } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import {
	faArrowUp,
	faCalendarDays,
	faBars,
	faTimes,
	faStars,
	faConciergeBell,
	faRulerCombined,
	faBlanket,
	faUsers,
} from '@fortawesome/pro-light-svg-icons';
import { faMinus, faPlus } from '@fortawesome/pro-solid-svg-icons';
import { faChevronDown, faChevronUp, faBed, faBell, faCircleCheck } from '@fortawesome/pro-regular-svg-icons';
import {
	faFacebook,
	faInstagram,
	faLinkedin,
	faPinterest,
	faSoundcloud,
	faSpotify,
	faTiktok,
	faTwitter,
	faWhatsapp,
	faXTwitter,
	faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import { faStar, faStarHalf, faQuoteLeft } from '@fortawesome/pro-solid-svg-icons';

// This is important, we are going to let Nuxt.js worry about the CSS
config.autoAddCss = false;

library.add(
	faArrowUp,
	faBars,
	faBed,
	faBell,
	faBlanket,
	faCalendarDays,
	faChevronDown,
	faChevronUp,
	faCircleCheck,
	faConciergeBell,
	faFacebook,
	faInstagram,
	faLinkedin,
	faMinus,
	faPinterest,
	faPlus,
	faQuoteLeft,
	faRulerCombined,
	faSoundcloud,
	faSpotify,
	faStar,
	faStarHalf,
	faStars,
	faTiktok,
	faTimes,
	faTwitter,
	faUsers,
	faWhatsapp,
	faXTwitter,
	faYoutube,
);

export default defineNuxtPlugin(({ vueApp }) => {
	vueApp.component('font-awesome-icon', FontAwesomeIcon, {});
});
